import * as React from "react"
import { Link } from "gatsby"

const Nav = () => {
  const linkStyle =
    "mx-2 mb-2 bg-transparent hover:bg-amber-500 text-amber-50 font-semibold hover:text-amber-50 py-2 px-4 border border-amber-50 hover:border-transparent focus:ring-amber-300 rounded"

  return (
    <nav className="flex flex-row flex-wrap justify-center text-center my-6 text-xl md:text-2xl">
      <Link className={linkStyle} to="/">
        Blog
      </Link>
      <Link className={linkStyle} to="/problem-solving">
        Solve
      </Link>
      <Link className={linkStyle} to="/fun-stuff">
        Build
      </Link>
      <Link className={linkStyle} to="/talks/all">
        🔊
      </Link>
    </nav>
  )
}

export default Nav
