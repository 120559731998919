import * as React from "react"
import { Link } from "gatsby"
import Nav from "./nav"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header = (
    <>
      <div className="text-center mb-16">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          <Link to="/" className="border-b-2">
            {title}
          </Link>
        </h1>
        <span className="text-xl md:text-3xl font-bold">
          with <Link to="/about">Ben Hofferber</Link>
        </span>
      </div>
      <Nav />
    </>
  )

  return (
    <div
      data-is-root-path={isRootPath}
      style={{ gridTemplateRows: "auto 1fr auto" }}
      className="grid h-screen w-full"
    >
      <div>
        <div className="absolute top-0 w-full skew-y-[-3deg] lg:skew-y-[-2deg] z-[-1] origin-[0] bg-slate-700 dark:bg-slate-900 pt-80 md:pt-96">
          <div></div>
        </div>
        <header className="bg-transparent text-amber-50 p-2 pt-12 mt-6 mb-20 md:mb-32">
          {header}
        </header>
      </div>

      <main>{children}</main>

      <div>
        <div className="relative overflow-hidden h-44">
          <div className="absolute w-full -skew-y-3 h-44 -bottom-20 bg-slate-700 dark:bg-slate-900"></div>
        </div>
        <footer className="bg-slate-700 dark:bg-slate-900 p-6 pb-20 text-amber-50">
          <div className="text-center text-lg">
            <span data-id="copyright">
              © {new Date().getFullYear()}{" "}
              <Link to="/about">Ben Hofferber</Link>
            </span>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Layout
